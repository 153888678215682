import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import {
  fetcher,
  queryString,
  useRecoilQuery,
  useRecoilQueryInterVal,
} from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import {
  activeFeventListForWorkerDataAtom,
  activeFeventListForWorkerStatusAtom,
  feventByShopDataAtomFamily,
  feventByShopStatusAtomFamily,
  feventCountByBugoBrandDataAtomFamily,
  feventCountByBugoBrandStatusAtomFamily,
  feventDataAtomFamily,
  feventDoingListDataAtom,
  feventDoingListStatusAtom,
  feventListByBugoBrandDataAtomFamily,
  feventListByBugoBrandStatusAtomFamily,
  feventListDataAtom,
  feventListStatusAtom,
  feventStatusAtomFamily,
} from './fevent.atom';

export const feventHookUrl = (feventId: string, bugoRole: string) =>
  `${bugoRole}/fevent/${feventId}/?${queryString({
    populate: [
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'roomInfo',
      },
      {
        path: 'deceasedInfo.dpImage',
      },
      {
        path: 'memberOrderList',
        populate: [
          {
            path: 'cashAccount',
          },
          {
            path: 'bugo',
          },
        ],
      },
      {
        path: 'cashAccounts',
      },
    ],
  })}`;

//* FeventIdRouter 부고수정 부고전송 답례글 전송
export const useFeventHook = function (id: string, disabled?: boolean) {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    feventStatusAtomFamily(id),
    feventDataAtomFamily(id),
    feventHookUrl(id, bugoRole()),
    fetcher,
    disabled,
  );
};

export const feventListHookUrl = (page: number, step: number, bugoRole: LowerRole) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
      limit: step,
      skip: step * page,
    },
    populate: [
      {
        path: 'roomInfo',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'memberOrderList',
      },
    ],
  })}`;
};

export const useFeventListhook = () => {
  const { bugoRole, isBugoAgencyWorker } = useAuth();
  const urlFunc = (page: number) => {
    return feventListHookUrl(page, 100, bugoRole());
  };

  useRecoilQueryInterVal(
    feventListStatusAtom,
    feventListDataAtom,
    urlFunc,
    fetcher,
    2000,
    isBugoAgencyWorker(),
  );
};

//* bugoManagPage에서 사용중
export const activeFeventListForWorkerHookUrl = (
  bugoRole: LowerRole,
  isAdmin: boolean,
) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: {
        createdAt: -1,
      },
    },
    filter: {
      'meta.isDeleted': { $ne: true },
    },
    populate: isAdmin
      ? [
          {
            path: 'roomInfo',
          },
          {
            path: 'funeralHomeInfo',
          },
          {
            path: 'memberOrderList',
          },
          {
            path: 'user',
          },
        ]
      : [
          {
            path: 'roomInfo',
          },
          {
            path: 'funeralHomeInfo',
          },
          {
            path: 'memberOrderList',
          },
        ],
  })}`;
};

export const useActiveFeventListForWorkerHook = () => {
  const { bugoRole, isAdmin } = useAuth();

  useRecoilQuery(
    activeFeventListForWorkerStatusAtom,
    activeFeventListForWorkerDataAtom,
    activeFeventListForWorkerHookUrl(bugoRole(), isAdmin()),
    fetcher,
  );
};

export const feventDoingListHookUrl = (page: number, bugoRole: LowerRole) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
      limit: 10,
      skip: 10 * page,
    },
    filter: { status: FeventStatus.Doing },
    populate: [
      { path: 'user' },
      {
        path: 'roomInfo',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'memberOrderList',
      },
    ],
  })}`;
};

export const useFeventDoingListhook = (page = 0) => {
  const { isAuthenticated, bugoRole } = useAuth();
  useRecoilQuery(
    feventDoingListStatusAtom,
    feventDoingListDataAtom,
    feventDoingListHookUrl(page, bugoRole()),
    fetcher,
    !isAuthenticated,
  );
};

//*상조관리자가 볼 fevent
//TODO:paginateion해놓으삼@김정민
export const feventListByBugoBrandHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    filter: {
      bugoBrand: bugoBrandId,
    },
    populate: [
      {
        path: 'user',
      },
      {
        path: 'shopOrders',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'memberOrderList',
      },
    ],
  })}`;
};

export const useFeventListByBugoBrandhook = (bugoBrandId: string) => {
  const { bugoRole, isAdmin, isBugoAgencyAdmin } = useAuth();
  useRecoilQuery(
    feventListByBugoBrandStatusAtomFamily(bugoBrandId),
    feventListByBugoBrandDataAtomFamily(bugoBrandId),
    feventListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
    fetcher,
    !(isAdmin() || isBugoAgencyAdmin()),
  );
};

export const feventCountByBugoBrandHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
  dateFrom?: Date,
  dateTo?: Date,
) => {
  const dateFilterOn = dateFrom || dateTo;

  return `${bugoRole}/fevent/count/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    filter: {
      bugoBrand: bugoBrandId,
      createdAt: dateFilterOn && {
        $gte: dateFrom,
        $lt: dateTo,
      },
    },
  })}`;
};

export const useFeventCountByBugoBrandhook = (
  bugoBrandId: string,
  atomId: string,
  dateFrom?: Date,
  dateTo?: Date,
) => {
  const { bugoRole, isAdmin, isBugoAgencyAdmin } = useAuth();
  useRecoilQuery(
    feventCountByBugoBrandStatusAtomFamily(atomId),
    feventCountByBugoBrandDataAtomFamily(atomId),
    feventCountByBugoBrandHookUrl(bugoBrandId, bugoRole(), dateFrom, dateTo),
    fetcher,
    !(isAdmin() || isBugoAgencyAdmin()),
  );
};

export const feventByShopHookUrl = (shopId: string, sellerRole: LowerRole) => {
  return `${sellerRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    populate: [
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'roomInfo',
      },

      {
        path: 'memberOrderList',
      },
    ],
    filter: {
      status: { $in: [FeventStatus.Doing, FeventStatus.Todo] },
    },
  })}`;
};

export const useFeventByShopHook = (shopId: string) => {
  const { sellerRole } = useAuth();
  useRecoilQuery(
    feventByShopStatusAtomFamily(shopId),
    feventByShopDataAtomFamily(shopId),
    feventByShopHookUrl(shopId, sellerRole()),
    fetcher,
  );
};
