import { FC, useEffect } from 'react';

import { useRecoilState } from 'recoil';

import { Icon } from '@shared/components/icons';
import Splash from '@shared/containers/Splash/SplashAgency';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import bottomBanner from 'src/assets/banner/bottomBanner.jpg';

import { AgencyAdminMain } from '../containers/BugoMain/AgencyAdminMain';
import { WorkerMain } from '../containers/BugoMain/WorkerMain';
import { useSplashAtom } from '../state/atom/splash.atom';

/**
 * 부고 메인 홈페이지
 * @returns page, container
 */

const BugoMainPage: FC = function BugoMainPage() {
  // States
  const [showSplash, setShowSplash] = useRecoilState(useSplashAtom);
  const { token, userProfile, isBugoAgencyAdmin } = useAuth();
  const navigate = useNavigate();

  const { CS } = Icon();

  useEffect(() => {
    if (!token) return;
    if (!userProfile) {
      navigate('/signup/init');
    }
  }, [token, userProfile, navigate]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showSplash) {
      timeout = setTimeout(() => setShowSplash(false), 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showSplash, setShowSplash]);

  return (
    <>
      <Splash isLoading={showSplash} />
      <div className="space-y-6 p-4 py-4 pb-0">
        {isBugoAgencyAdmin() ? (
          // 에이전시 관리자용
          <AgencyAdminMain />
        ) : (
          <WorkerMain />
        )}
        {/* 관리자용 */}
        {/* {(isBugoAgencyAdmin() || isAdmin()) && <RegisteredBugoList />} */}

        <div className="space-y-4">
          <div className="-mx-4">
            <a href="https://www.preedlife.com/mobile/main">
              <img src={bottomBanner} />
            </a>
          </div>
          <div className="space-y-2 pb-4 text-center font-medium">
            <p className="text-lg font-bold">문의사항 발생시 아래로 연락주세요</p>
            <a href={`tel:01040539530`}>
              <div className="center-box gap-2">
                <CS /> <p>개발실장 김기훈 : 010-4053-9530</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BugoMainPage;
